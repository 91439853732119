import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Prepare all the offsite products you need for your project using Supply Chains.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Creating Supply Chains</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Adding organisations to your Supply Chains</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Removing Organisations from your Supply Chain</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Archiving Supply Chains</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deleting Supply Chains</AnchorLink>
    </AnchorLinks>
    <p>{`Use `}<strong parentName="p">{`Supply Chains`}</strong>{` to create and manage named groups of organisations for your convenience on particular projects, regions, etc. Supply chains are only visible to those inside your organisation.`}</p>
    <h2>{`Creating Supply Chains`}</h2>
    <p>{`To create a new Supple Chain:`}</p>
    <ol>
      <li parentName="ol">{`On the KOPE landing page, Click the `}<inlineCode parentName="li">{`New Supply Chain`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/50_kope-landing-page-new-supply-chain.png",
          "alt": "50_kope-landing-page-new-supply-chain.png"
        }}></img></li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`Create New Supply Chain`}</inlineCode>{` popup will appear.`}</li>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`New Name`}</inlineCode>{` field, enter the name of your supply chain.`}</li>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`Description`}</inlineCode>{` field, enter a description of your supply chain.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Save`}</inlineCode>{` button to finish creating your supply chain.`}</li>
    </ol>
    <h2>{`Adding organisations to your supply chain`}</h2>
    <p>{`To add an organisation to your supply chain:`}</p>
    <ol>
      <li parentName="ol">{`Select the supply chain on the left side of the screen.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Add Organisation`}</inlineCode>{` in the top right.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/51_supply-chain-add-organisation.png",
          "alt": "51_supply-chain-add-organisation.png"
        }}></img></li>
      <li parentName="ol">{`Select the organisations that form your supply chain.`}</li>
    </ol>
    <h2>{`Removing Organisations from your supply chain`}</h2>
    <p>{`To remove an organisation from your supply chain:`}</p>
    <ol>
      <li parentName="ol">{`Select the supply chain on the left side of the screen.`}</li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the organisation you wish to remove.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Remove`}</inlineCode>{`.`}</li>
    </ol>
    <h2>{`Archiving Supply Chains`}</h2>
    <p>{`To archive and entire supply chain:`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the supply chain on the left side of the screen.`}</li>
      <li parentName="ol">{`In the menu, click `}<inlineCode parentName="li">{`Archive`}</inlineCode>{`.`}</li>
    </ol>
    <h2>{`Deleting Supply Chains`}</h2>
    <p>{`To delete an entire supply chain:`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the supply chain on the left side of the screen.`}</li>
      <li parentName="ol">{`In the menu, click `}<inlineCode parentName="li">{`Delete`}</inlineCode>{`.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      